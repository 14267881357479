import { Plugin } from '@ckeditor/ckeditor5-core';
import { ButtonView } from '@ckeditor/ckeditor5-ui';
import icon from './icon.svg'


export default class ImportDocument extends Plugin {
	init() {
		//region set translations
		Object.assign(window.CKEDITOR_TRANSLATIONS['es'].dictionary, {
			'Import from Word': 'Importar de Word',
		});
		//endregion

		const editor = this.editor;

		editor.ui.componentFactory.add('importDocument', locale => {
			// The button will be an instance of ButtonView.
			const button = new ButtonView(locale);

			const { t } = editor.locale;

			button.set({
				label: t('Import from Word'),
				icon: icon,
				tooltip: true,
                keystroke: 'Ctrl+Alt+I'
			});

            button.extendTemplate({
                attributes: {
                    id: 'hla-cke-import-document',
                }
            });

			//Execute a callback function when the button is clicked
			button.on('execute', () => {
				if (typeof pluginImportDocument === 'function') {
					pluginImportDocument()
				} else {
					console.error("The function 'pluginImportDocument' does not exist.")
				}
			});

			return button;
		});
	}
}
