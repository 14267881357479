import { Plugin } from '@ckeditor/ckeditor5-core'
import { ButtonView } from '@ckeditor/ckeditor5-ui'
import icon from './icon.svg'
import viewToPlainText from '@ckeditor/ckeditor5-clipboard/src/utils/viewtoplaintext'


export default class AISimplification extends Plugin {
	init() {
		//region set translations
		Object.assign(window.CKEDITOR_TRANSLATIONS['es'].dictionary, {
			'AI Simplification': 'Simplificación con IA',
		})
		//endregion

		const editor = this.editor

		editor.ui.componentFactory.add('aiSimplification', locale => {
			// The button will be an instance of ButtonView.
			const button = new ButtonView(locale)

			const { t } = editor.locale

			button.set({
				label: t('AI Simplification'),
				withText: true,
				icon: icon,
                tooltip: true,
                keystroke: 'Ctrl+Alt+A'
			})

            button.extendTemplate({
                attributes: {
                    class: ['btn-hla-cke-plugin ai-simplification'],
                    id: 'hla-cke-ai-simplification'
                }
            })

			//Execute a callback function when the button is clicked
			button.on('execute', () => {
				const plaintText = viewToPlainText(editor.editing.view.document.getRoot())
				if (typeof pluginAiSimplification === 'function') {
					pluginAiSimplification(plaintText)
				} else {
					console.error("The function 'pluginAiSimplification' does not exist.")
				}
			})

			return button
		})
	}
}
